import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
// import SideBar from '@/components/AppSidebar.vue'
import { useUserStore } from '@/stores/user'

// const isAuthenticated = (to, from, next) => {
//   const store = useUserStore()
//   // console.log('isAuthenticated', store.getters.isAuthenticated)
//   if (store.isAuthenticated) {
//     next()
//     return
//   }
//   next('/login')
// }

async function routerBeforeEach(to, from, next) {
  console.log(to.path)
  if (to.path !== '/login') {
    const store = useUserStore()
    try {
      const isLoggedIn = await store.me()
      console.log('isLoggedIn', isLoggedIn)
      if (!isLoggedIn) {
        next('/login')
      } else {
        next()
      }  
    } catch (e) {
      console.log('error router')
      console.log(e)
      next('/login')
    }
    
  } else {
    next()
  }
}


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/patients'
    // component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView//import('../views/LoginView.vue')
  },
  {
    path: '/patients',
    name: 'PatientsList',
    components: {
      // sidebar: SideBar,
      default: () => import(/* webpackChunkName: "patients" */ '../views/Patients/PatientsList.vue')
    },
  },
  {
    path: '/patients/create',
    name: 'PatientsCreate',
    components: {
      // sidebar: SideBar,
      default: () => import(/* webpackChunkName: "patients" */ '../views/Patients/PatientsCreate.vue')
    },
  },
  {
    path: '/patients/edit/:patientId',
    name: 'PatientsEdit',
    components: {
      // sidebar: SideBar,
      default: () => import(/* webpackChunkName: "patients" */ '../views/Patients/PatientView.vue')
    },
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: () => import('../views/Orders/OrdersList.vue')
  },
  {
    path: '/orders/edit/step2/:orderId',
    name: 'OrderEditStep2',
    component: () => import('../views/Orders/OrdersEditStep2.vue')
  },
  {
    path: '/orders/edit/step3/:orderId',
    name: 'OrderEditStep3',
    component: () => import('../views/Orders/OrdersEditStep3.vue')
  },
  {
    path: '/orders/edit/:orderId',
    name: 'OrderEditStep1',
    component: () => import('../views/Orders/OrdersEditStep1.vue')
  },
  {
    path: '/orders/step1',
    name: 'OrdersAddStep1',
    component: () => import('../views/Orders/OrdersAddStep1.vue')
  },
  {
    path: '/orders/step2',
    name: 'OrdersAddStep2',
    component: () => import('../views/Orders/OrdersAddStep2.vue')
  },
  {
    path: '/orders/step3',
    name: 'OrdersAddStep3',
    component: () => import('../views/Orders/OrdersAddStep3.vue')
  },
  {
    path: '/orders/process/:orderId',
    name: 'OrdersProcess',
    component: () => import('../views/Orders/OrderProcess.vue')
  },
  {
    path: '/orders/cancelled',
    name: 'OrdersCancelled',
    component: () => import('../views/Orders/OrderCancelled.vue')
  },
  {
    path: '/orders/view/:orderId',
    name: 'OrderView',
    component: () => import('../views/Orders/OrderView.vue')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/Inventory/ProductsList.vue')
  },
  {
    path: '/inventory/:productPriceId',
    name: 'InventoryDetails',
    component: () => import('../views/Inventory/InventoryDetails.vue')
  },
  // {
  //   path: '/dispense/new',
  //   name: 'DispenseNew',
  //   component: () => import('../views/Dispense/DispenseNew.vue')
  // },
  {
    path: '/dispense/history',
    name: 'DispenseHistory',
    component: () => import('../views/Dispense/DispenseHistory.vue')
  },
  {
    path: '/dispense/history/details/:orderId',
    name: 'DispenseHistoryDetails',
    component: () => import('../views/Dispense/DispenseHistoryDetails.vue')
  },
  {
    path: '/dispense/new',
    name: 'DispenseNewStep2',
    component: () => import('../views/Dispense/DispenseNew2.vue')
  },
  {
    path: '/dispense/new/step3',
    name: 'DispenseNewStep3',
    component: () => import('../views/Dispense/DispenseNew3.vue')
  },
  {
    path: '/dispense/new/step4',
    name: 'DispenseNewStep4',
    component: () => import('../views/Dispense/DispenseNew4.vue')
  },
  {
    path: '/dispense/new/step5',
    name: 'DispenseNewStep5',
    component: () => import('../views/Dispense/DispenseNew5.vue')
  },
  {
    path: '/dispense/new/success',
    name: 'DispenseNewSuccess',
    component: () => import('../views/Dispense/DispenseNewSuccess.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => routerBeforeEach(to, from, next))

export default router
