import 'mdb-vue-ui-kit/css/mdb.min.css'
import 'mdb-vue-wysiwyg-editor/css/mdb-vue-wysiwyg-editor.min.css'
import 'mdb-vue-table-editor/css/mdb-vue-table-editor.min.css'
import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import axios from './plugins/axios'
import { createPinia } from 'pinia'
// import { useUserStore } from './stores/user'
import './scss/main.css'

const pinia = createPinia()

const app = createApp(App)
.use(pinia)
.use(router)
// .use(axios, {
//   baseUrl: 'http://localhost:8000/api',
//   token: window.localStorage.getItem('prms-token') || null
// })

app.mount('#app')

// const userStore = useUserStore()
// router.beforeEach((to, from, next) => {
//   if (to.path !== '/login') {
//     userStore.me()
//       .then(function(res) {
//         console.log('beforeEach', res)
//         next()
//       })
//       .catch(() => {
//         next('/login')
//       })
//   }
// })

