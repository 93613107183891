import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from '@/plugins/axios'
import Axios from 'axios'
export const useUserStore = defineStore('user', () => {
  const token = ref(window.localStorage.getItem('prms-token') || null)
  const user = ref(null)

  const isAuthenticated = computed(() => {
    return !!user.value
  })

  function setAuthUser(obj) {
    token.value = obj.token
    user.value = obj.user
    window.localStorage.setItem('prms-token', obj.token)
    Axios.defaults.headers.common.Authorization = `Bearer ${obj.token}`
  }

  function logout() {
    token.value = null
    user.value = null
    window.localStorage.setItem('prms-token', null)
  }

  async function me() {
    return new Promise((resolve, reject) => {
      axios.get('/me')
        .then((resp) => {
          user.value = resp.data
          resolve(true)
        })
        .catch((err) => {
          console.error(err)
          reject(false)
        })

    })
  }

  return {
    token,
    user,
    isAuthenticated,
    setAuthUser,
    me,
    logout
  }

})