<template>

<header>
  <img title="logo" :src="logo" />
</header>

<div class="container">
        <div class="row justify-content-center align-items-center" style="height:100vh">
            <MDBCol sm="10" md="6">
              <h3 style="text-align:left">Sign in</h3>
                        <form @submit="login" action="" autocomplete="off">
                            <div class="form-group mt-5">
                                <MDBInput v-model="email" type="email" label="Email"></MDBInput>
                            </div>
                            <div class="form-group mt-3">
                                <MDBInput v-model="password" type="password" label="Password"></MDBInput>
                            </div>
                            <MDBBtn color="primary" class="mt-3 login-btn" size="lg" :disabled="isDisabled" @click="login" style="float:left">Sign in</MDBBtn>
                            <!-- <button :disabled="isDisabled" @click="login" style="float:left" type="button" id="sendlogin" class="btn btn-primary mt-3">Sign in</button> -->
                        </form>
              </MDBCol>
        </div>
    </div>


  <!-- <div class="hello">
    <input type="email" v-model="email" />
    <input type="password" v-model="password" />
    <button type="submit" @click="login()">Login</button>
  </div> -->
</template>

<script>
import { MDBInput,MDBBtn,MDBCol } from 'mdb-vue-ui-kit';
import { useUserStore } from '@/stores/user'
import axios from '@/plugins/axios'
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router'
export default {
  components: {
    MDBInput,MDBBtn,MDBCol
  },
  setup() {
    const email = ref('')
    const password = ref('')
    const router = useRouter()
    const store = useUserStore()
    const logo = ref('')

    const isDisabled = computed(() => {
      if (!email.value || !password.value) return true
      return false
    })

    const login = () => {
      axios.post('/login', {email:email.value, password: password.value})
        .then((resp) => {
          store.setAuthUser(resp.data)
          router.push('/patients')
        })
    }

    axios.get('/logo')
      .then((resp) => {
        logo.value = resp.data.logo
      })

    return {
      email,
      password,
      login,
      isDisabled,
      logo
    }
  }
}

// export default {
//   data: () => ({
//     email: '',
//     password: ''
//   }),
//   methods: {
//     login() {
//       const store = useUserStore()
//       console.log(this.$router)
      
//       axios.post('/login', {email: this.email, password: this.password})
//         .then((resp) => {
//           store.setAuthUser(resp.data)
//           // const router = useRouter()
//           this.$router.push('/patients')
//         })

//     }
//   }
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
header {
  height:24px;
  width:100%;
  text-align: left;
  padding: 10px;
}
.login-btn {
  float: left;
  height: 48px;
  padding: 12px 18px;  
}
</style>
